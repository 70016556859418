import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/page";
import ProductsListWithFilters from "../../containers/shop/ProductsListWithFilters";

const ShopProductsPage = ({ data }: { data: Queries.ShopQueryDataQuery }) => {
  return (
    <Page {...data.datoCmsWebPage} container="fluid" modules={[]}>
      <ProductsListWithFilters products={data.allDatoCmsProduct.nodes} />
    </Page>
  );
};

export const query = graphql`
  query ShopQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "shop" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
    allDatoCmsProduct(
      filter: { locale: { eq: $locale }, disablePlp: { ne: true } }
      sort: { fields: position }
    ) {
      nodes {
        ...DatoCmsProductItemFragment
      }
    }
  }
`;

export default ShopProductsPage;
